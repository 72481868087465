export const getQueryData = (route: any, router: any) => {
  if(route.query.user || route.query.lang || route.query.country || route.query.mode ) {
    const user = {
      ...JSON.parse(localStorage.getItem('user') || '{}'),
      ...route.query.user && {email: route.query.user},
      ...route.query.lang && {lang: route.query.lang},
      ...route.query.country && {country: route.query.country},
      ...route.query.mode && {mode: route.query.mode},
    }
    localStorage.setItem('user', JSON.stringify(user));
    const query = Object.assign({}, route.query);
    delete query.user;
    delete query.mode;
    delete query.lang;
    delete query.country;
    router.replace({query})
  }
}